import { useState, FC } from 'react';
import { ToggleButton } from '../ToggleButton';
import { Checkbox } from '../Checkbox';
import './Accordion.scss';

interface AccordionProps {
  title: string;
  description?: string;
  children: React.ReactElement | null;
  id: string;
  checked: boolean;
  linkInfo: { url: string; text: string } | null;
  onChange: (itemId: string, value: boolean) => void;
}

export const Accordion: FC<AccordionProps> = ({
  title,
  description,
  children,
  id,
  checked,
  linkInfo,
  onChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  function onClick() {
    if (!children && !description) return;

    setIsExpanded(!isExpanded);
  }

  function onItemClick(itemId: string, value: boolean) {
    if (children) return;

    onChange(itemId, value);
  }

  let text = description as string | JSX.Element;

  if (linkInfo && description) {
    const replace = description.replace(
      linkInfo.text,
      `<a class="check-list_link" target="_blank" href="${linkInfo.url}">${linkInfo.text}</a>`
    );

    text = <div dangerouslySetInnerHTML={{ __html: replace }} />;
  }

  return (
    <div className="custom-accordion">
      <div className="custom-accordion_header">
        <Checkbox
          onChange={onItemClick}
          value={checked}
          id={id}
          label={title}
          labelClassName="custom-accordion_title"
          disabled={!!children}
        />

        <ToggleButton onClick={onClick} value={isExpanded} disabled={!children && !text} />
      </div>
      <div className="custom-accordion_body">
        {isExpanded && (
          <>
            {text && <span className="custom-accordion_description">{text}</span>}
            {children && <div className="custom-accordion_content">{children}</div>}
          </>
        )}
      </div>
    </div>
  );
};
