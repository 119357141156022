"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const PushNotification = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 28 28", fill: "currentColor", width: size || "28", height: size || "28" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M26 3.62H2C1.72386 3.62 1.5 3.84385 1.5 4.11999V6.9701H26.5V4.11999C26.5 3.84385 26.2761 3.62 26 3.62ZM1.5 23.64V8.4701H26.5V23.64C26.5 23.9161 26.2761 24.14 26 24.14H2C1.72386 24.14 1.5 23.9161 1.5 23.64ZM2 2.12C0.895431 2.12 0 3.01542 0 4.11999V23.64C0 24.7446 0.895431 25.64 2 25.64H26C27.1046 25.64 28 24.7446 28 23.64V4.11999C28 3.01543 27.1046 2.12 26 2.12H2ZM4 10C3.44772 10 3 10.4477 3 11V15C3 15.5523 3.44772 16 4 16H24C24.5523 16 25 15.5523 25 15V11C25 10.4477 24.5523 10 24 10H4Z", fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd" }) })));
};
PushNotification.displayName = 'PushNotification';
exports.default = PushNotification;
