"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Link = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M7.3125 5.3125C7.72671 5.3125 8.0625 5.64829 8.0625 6.0625C8.0625 6.47671 7.72671 6.8125 7.3125 6.8125H6.8125C5.00032 6.8125 3.53125 8.28157 3.53125 10.0938C3.53125 11.9059 5.00032 13.375 6.8125 13.375H7.3125C7.72671 13.375 8.0625 13.7108 8.0625 14.125C8.0625 14.5392 7.72671 14.875 7.3125 14.875H6.8125C4.17189 14.875 2.03125 12.7344 2.03125 10.0938C2.03125 7.45314 4.17189 5.3125 6.8125 5.3125H7.3125ZM11.9688 6.0625C11.9688 5.64829 12.3045 5.3125 12.7188 5.3125H13.1875C15.8281 5.3125 17.9688 7.45314 17.9688 10.0938C17.9688 12.7344 15.8281 14.875 13.1875 14.875H12.7188C12.3045 14.875 11.9688 14.5392 11.9688 14.125C11.9688 13.7108 12.3045 13.375 12.7188 13.375H13.1875C14.9997 13.375 16.4688 11.9059 16.4688 10.0938C16.4688 8.28157 14.9997 6.8125 13.1875 6.8125H12.7188C12.3045 6.8125 11.9688 6.47671 11.9688 6.0625ZM5.85834 10.079C5.85834 9.66477 6.19412 9.32898 6.60834 9.32898H13.3604C13.7746 9.32898 14.1104 9.66477 14.1104 10.079C14.1104 10.4932 13.7746 10.829 13.3604 10.829H6.60834C6.19412 10.829 5.85834 10.4932 5.85834 10.079Z", fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd" }) })));
};
Link.displayName = 'Link';
exports.default = Link;
