import { FC } from 'react';
import {
  Accordion,
  CheckList,
  ContactCard,
  MediaCard,
  Ritual,
  Section,
  TaskBar,
} from '../../ui-kit';
import { observer } from 'mobx-react-lite';
import { appStore } from '../../stores';
import './Main.scss';

export const Main: FC = observer(() => {
  const structure = appStore.structure;
  const { rituals, meetings, media, designerPlanList, firstTasks } = structure;

  return (
    <main className="main">
      {!!designerPlanList.items.length && (
        <Section
          title={designerPlanList.name}
          path={designerPlanList.path}
          description={designerPlanList.description}
        >
          <div className="main_col-wrapper">
            {designerPlanList.items.map(item => {
              return (
                <Accordion
                  checked={item.status}
                  id={item.id}
                  title={item.name}
                  description={item.description}
                  key={item.id}
                  onChange={appStore.changeItemStatus}
                  linkInfo={item.linkInfo}
                >
                  {item.options && (
                    <CheckList
                      itemId={item.id}
                      options={item.options}
                      onChange={appStore.changeItemStatus}
                    />
                  )}
                </Accordion>
              );
            })}
          </div>
        </Section>
      )}

      {!!meetings.items.length && (
        <Section path={meetings.path} title={meetings.name} description={meetings.description}>
          <div className="main_card-wrapper">
            {meetings.items.map(item => (
              <ContactCard
                imgSrc={item.avatarSrc}
                title={item.title}
                name={item.userName}
                position={item.position}
                link={item.linkSrc}
                key={item.id}
                labels={item.labels}
                linkText={item.linkText}
                buttonLink={item.buttonLink}
                tags={item.tags}
              />
            ))}
          </div>
        </Section>
      )}

      {!!media.items.length && (
        <Section path={media.path} title={media.name} description={media.description}>
          <div className="main_card-wrapper">
            {media.items.map(item => (
              <MediaCard
                imgSrc={item.imgSrc}
                title={item.title}
                linkText={item.linkText}
                key={item.id}
                linkSrc={item.linkSrc}
                checked={item.status}
                id={item.id}
                onChange={appStore.changeItemStatus}
                labels={item.labels}
                tags={item.tags}
              />
            ))}
          </div>
        </Section>
      )}

      {!!firstTasks.items.length && (
        <Section
          path={firstTasks.path}
          title={firstTasks.name}
          description={firstTasks.description}
        >
          <div className="main_col-wrapper">
            {firstTasks.items.map(item => (
              <TaskBar
                checked={item.status}
                title={item.title}
                id={item.id}
                linkSrc={item.buttonLinkUrl}
                key={item.id}
                onChange={appStore.changeItemStatus}
                labels={item.labels}
                tags={item.tags}
              />
            ))}
          </div>
        </Section>
      )}

      {!!rituals.items.length && (
        <Section path={rituals.path} title={rituals.name} description={rituals.description}>
          <div className="main_card-wrapper">
            {rituals.items.map(({ title, description, id, labels, tags, frequency }) => (
              <Ritual
                title={title}
                description={description}
                frequency={frequency}
                key={id}
                labels={labels}
                tags={tags}
              />
            ))}
          </div>
        </Section>
      )}
    </main>
  );
});
