export const COLUMN_NAMES = {
  IMAGE: 'Image',
  STATUS: 'Status',
  LINK: 'Link',
  BUTTON_TEXT: 'Button text',
  DESCRIPTION: 'Description',
  PERSON: 'Person',
  DEPARTMENT: 'Department',
  WEEK: 'Week',
  BUTTON_LINK: 'Button Link',
  FREQUENCY: 'Frequency',
  TAGS: 'Tags',
  DESCRIPTION_LINK: 'Description Link',
};

export const GROUP_NAMES = {
  MEDIA: 'Lectures/Presentations/Videos',
  FIRST_TASKS: 'First tasks',
  RITUALS: 'Rituals',
  MEETINGS: 'Intro meetings',
  BECOMING_DESIGNER: 'must-have tools',
  WELCOME: 'Welcome popup',
};

export const WELCOME_ITEMS_NAME = {
  BUDDY: 'Your buddy',
  TEAM_NAME: 'Team Name',
  MENTOR: 'Your mentor',
  PRODUCT_LINK: 'Product Design Onboarding',
  ONBOARDING_LINK: 'Onboarding website',
};

export const ITEM_COMPLETED_STATUS = 'Done';
export const STATUS_COLUMN_NAME = 'Status';
export const DEFAULT_STATUS_COL_ID = 'status';
export const HIDDEN_STATUS_NAMES = ['Not relevant'];

export const LABEL_STATUS_COLOR = '#ffffff';

export const SUPPORTED_COLUMN_TYPES = ['color', 'text', 'multiple-person', 'long-text'];

export const MODAL_SUFFIX = '_modal';

export const INIT_STRUCTURE_STATE = {
  media: {
    name: 'Lectures',
    path: 'lectures',
    description: 'Take a deep dive into how our company works.',
    items: [],
  },
  designerPlanList: {
    name: 'Becoming a monday.com designer',
    path: 'must-have_tools',
    description: "Let's start by setting up your workspace and must-have tools.",
    items: [],
  },
  firstTasks: {
    name: GROUP_NAMES.FIRST_TASKS,
    path: 'first_tasks',
    description: 'Congrats, you just got your first tasks. Feel free to ask your buddy for help.',
    items: [],
  },
  rituals: {
    name: GROUP_NAMES.RITUALS,
    path: 'rituals',
    description:
      'Rituals are regularly scheduled meetings designed to keep everyone on the same page. Ask your buddy to add you to the calendar invite for these meetings:',
    items: [],
  },
  meetings: {
    name: GROUP_NAMES.MEETINGS,
    path: 'intro_meetings',
    description:
      'Slack these people and schedule a meeting with them to better understand how we work. Feel free to invite your fellow new joiners.',
    items: [],
  },
  welcome: {},
  unsupportedGroup: [],
};

export const SUPPORTED_DOMAINS = ['monday.com', 'leanylabs.com'];
