import { appStore } from '../../stores';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { ReactComponent as GoogleIcon } from '../../assets/google-icon.svg';
import './LoginButton.scss';

export const LoginButton = () => {
  function loginHandler(response?: TokenResponse) {
    appStore.login(response);
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse: TokenResponse) => loginHandler(codeResponse),
    onError: () => loginHandler(),
  });

  return (
    <div className="login">
      <div className="button" onClick={() => googleLogin()}>
        <GoogleIcon />
        Log in with Google
      </div>
    </div>
  );
};
