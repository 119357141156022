"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Chart = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M4.30247 4.30247C5.77612 2.82882 7.7746 2.00067 9.85859 2C9.85885 2 9.85911 2 9.85938 2C9.85995 2 9.86053 2 9.86111 2C11.946 2 13.9455 2.82822 15.4198 4.30247C16.894 5.77671 17.7222 7.77622 17.7222 9.86111C17.7222 11.946 16.894 13.9455 15.4198 15.4198C13.9455 16.894 11.946 17.7222 9.86111 17.7222C7.77622 17.7222 5.77671 16.894 4.30247 15.4198C2.82822 13.9455 2 11.946 2 9.86111C2 7.77622 2.82822 5.77671 4.30247 4.30247ZM9.10938 3.54455C7.6992 3.71234 6.3775 4.34875 5.36313 5.36313C4.17019 6.55607 3.5 8.17404 3.5 9.86111C3.5 11.5482 4.17019 13.1662 5.36313 14.3591C6.55607 15.552 8.17404 16.2222 9.86111 16.2222C11.2958 16.2222 12.6806 15.7375 13.7964 14.8588L9.32904 10.3914C9.18839 10.2508 9.10938 10.06 9.10938 9.86111V3.54455ZM14.8573 13.7984C15.737 12.6822 16.2222 11.2967 16.2222 9.86111C16.2222 8.17404 15.552 6.55607 14.3591 5.36313C13.3439 4.34792 12.0209 3.7113 10.6094 3.54414V9.55045L14.8573 13.7984Z", fillRule: "evenodd", clipRule: "evenodd" }) })));
};
Chart.displayName = 'Chart';
exports.default = Chart;
