"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Recurring = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M16.1935 2C16.6211 2 16.9677 2.34662 16.9677 2.77419V5.87097C16.9677 6.29854 16.6211 6.64516 16.1935 6.64516H13.0968C12.6692 6.64516 12.3226 6.29854 12.3226 5.87097C12.3226 5.44339 12.6692 5.09677 13.0968 5.09677H14.1931C13.3335 4.36173 12.2903 3.86108 11.1651 3.65448C9.67105 3.38016 8.12812 3.64181 6.80804 4.39335C5.48795 5.1449 4.47539 6.33813 3.94866 7.76291C3.42194 9.1877 3.41484 10.7526 3.92862 12.1821C4.4424 13.6116 5.4441 14.814 6.75732 15.5775C8.07053 16.341 9.61103 16.6166 11.1075 16.3559C12.604 16.0951 13.9605 15.3147 14.938 14.152C15.9156 12.9894 16.4516 11.519 16.4516 10C16.4516 9.57244 16.7982 9.22582 17.2258 9.22582C17.6534 9.22582 18 9.57244 18 10C18 11.8836 17.3354 13.7068 16.1232 15.1485C14.911 16.5902 13.2289 17.5579 11.3733 17.8813C9.51767 18.2046 7.60746 17.8628 5.97907 16.9161C4.35069 15.9694 3.10858 14.4784 2.47149 12.7059C1.8344 10.9333 1.8432 8.99274 2.49634 7.22601C3.14948 5.45928 4.40506 3.97967 6.04197 3.04775C7.67887 2.11584 9.59211 1.79139 11.4447 2.13155C12.9375 2.40563 14.3137 3.09704 15.4194 4.11522V2.77419C15.4194 2.34662 15.766 2 16.1935 2ZM10.7742 7.93548C10.7742 7.50791 10.4276 7.16129 10 7.16129C9.57242 7.16129 9.22581 7.50791 9.22581 7.93548V10.5161C9.22581 10.7215 9.30737 10.9184 9.45256 11.0636L11.5171 13.1281C11.8194 13.4304 12.3096 13.4304 12.612 13.1281C12.9143 12.8257 12.9143 12.3355 12.612 12.0332L10.7742 10.1954V7.93548Z", fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd" }) })));
};
Recurring.displayName = 'Recurring';
exports.default = Recurring;
