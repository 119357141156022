"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const NewTab = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M3.66646 3H14.346C14.7681 3.00122 15.1821 3.15768 15.4953 3.4498C15.8103 3.74354 15.9985 4.15312 16 4.59258L16 4.59507L16 9.92688C16 10.3411 15.6642 10.6769 15.25 10.6769C14.8358 10.6769 14.5 10.3411 14.5 9.92688V8.07327H3.50583V14.2135C3.50583 14.2135 3.50899 14.2193 3.51509 14.225C3.52968 14.2386 3.55999 14.2545 3.60162 14.2545H10.1946C10.6088 14.2545 10.9446 14.5903 10.9446 15.0045C10.9446 15.4187 10.6088 15.7545 10.1946 15.7545H3.60162C3.19461 15.7545 2.79466 15.6042 2.49203 15.3219C2.18762 15.038 2.00583 14.6413 2.00583 14.2157V4.5968C2.00728 4.15622 2.19603 3.74556 2.51184 3.45103C2.82591 3.15812 3.24105 3.00122 3.66431 3L3.66646 3ZM3.50583 6.57327H14.5V4.59715C14.4999 4.58857 14.4964 4.56932 14.4722 4.54677C14.446 4.52232 14.4002 4.50034 14.3424 4.5H3.66791C3.60893 4.50035 3.56195 4.52277 3.5349 4.548C3.50987 4.57134 3.50597 4.59165 3.50583 4.60131V6.57327ZM15.7115 12.75C15.7115 12.3358 15.3757 12 14.9615 12C14.5473 12 14.2115 12.3358 14.2115 12.75V14.2115H12.75C12.3358 14.2115 12 14.5473 12 14.9615C12 15.3757 12.3358 15.7115 12.75 15.7115H14.2115V17.173C14.2115 17.5872 14.5473 17.923 14.9615 17.923C15.3757 17.923 15.7115 17.5872 15.7115 17.173V15.7115H17.173C17.5872 15.7115 17.923 15.3757 17.923 14.9615C17.923 14.5473 17.5872 14.2115 17.173 14.2115H15.7115V12.75Z", fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd" }) })));
};
NewTab.displayName = 'NewTab';
exports.default = NewTab;
