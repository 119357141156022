export interface ColumnValue {
  id: string;
  text: string;
  type: string;
  title: string;
  value: string | null;
}

export interface ActivityLog {
  createdAt: string;
  data: string;
  event: string;
  id: string;
  userId: string;
}

export interface Item {
  name: string;
  id: string;
  columnValues: ColumnValue[];
  group: {
    id: string;
    title: string;
  };
}

export interface SubItem extends Item {
  board: {
    id: string;
  };
}

export interface Label {
  text: string;
  color: string | null;
  textColor?: string;
  columnId: string;
}

export interface User {
  id: number;
  email: string;
  title: string | null;
  photoThumb: string;
  name: string;
}

export interface Column {
  id: string;
  title: string;
  settingsStr: string;
}

export enum ChecklistItemType {
  Color = 'color',
  Person = 'person',
  Text = 'text',
}

export type CustomArrayElement =
  | {
      title: string;
      type: ChecklistItemType.Color;
      columnId: string;
      options: {
        text: string;
        id: string;
        color: string;
      }[];
      value: {
        id: string;
        text: string;
        color: string;
      };
    }
  | {
      title: string;
      type: ChecklistItemType.Person;
      columnId: string;
      options: User[];
      value: User[];
    }
  | {
      title: string;
      type: ChecklistItemType.Text;
      columnId: string;
      value: string;
      options: null;
    };

export type StructureKeys = keyof Structure;
export type SupportedStructureKeys = Exclude<
  StructureKeys,
  'rituals' | 'meetings' | 'unsupportedGroup' | 'welcome'
>;

export type Tag = { color: string; id: number; name: string };

export interface Structure {
  media: Media;
  designerPlanList: DesignerPlanList;
  firstTasks: FirstTasks;
  rituals: Rituals;
  meetings: Meetings;
  welcome: {
    teamName: string;
    buddy: Mentor;
    mentor: Mentor;
    productLink: LinkValue;
    onboardingLink: LinkValue;
    [key: string]: unknown;
  };
  unsupportedGroup: Item[];
}

export interface LinkValue {
  url: string;
  text?: string;
}

export interface Mentor {
  title: string;
  id: string;
  linkText: string;
  userName: string;
  position: string;
  avatarSrc: string;
  buttonLink: string;
}

interface MediaItem {
  id: string;
  imgSrc: string;
  title: string;
  linkText: string;
  linkSrc: string;
  status: boolean;
  statusColId: string;
  labels: Label[];
  tags: Tag[];
}

interface Media {
  name: string;
  description: string;
  path: string;
  items: MediaItem[];
}

interface DesignerPlanItemOption {
  title: string;
  id: string;
  status: boolean;
  boardId: number;
  linkInfo: { url: string; text: string } | null;
}

interface DesignerPlanItem {
  id: string;
  name: string;
  description: string;
  linkInfo: { url: string; text: string } | null;
  status: boolean;
  statusColId: string;
  options: DesignerPlanItemOption[] | null;
}

interface DesignerPlanList {
  name: string;
  description: string;
  path: string;
  items: DesignerPlanItem[];
}

interface FirstTask {
  title: string;
  id: string;
  linkSrc: string;
  status: boolean;
  statusColId: string;
  labels: Label[];
  tags: Tag[];
  buttonLinkUrl: string;
}

interface FirstTasks {
  name: string;
  description: string;
  path: string;
  items: FirstTask[];
}

interface Ritual {
  title: string;
  id: string;
  description: string;
  labels: Label[];
  tags: Tag[];
  frequency: string;
}

interface Rituals {
  name: string;
  description: string;
  path: string;
  items: Ritual[];
}

interface Meeting {
  linkSrc: string;
  userName: string;
  position: string;
  avatarSrc: string;
  title: string;
  id: string;
  status: boolean;
  statusColId: string;
  labels: Label[];
  linkText: string;
  buttonLink: string;
  tags: Tag[];
}

interface Meetings {
  name: string;
  description: string;
  items: Meeting[];
  path: string;
}
