import { Column, ColumnValue, Item, LinkValue, SubItem, Tag, User } from '../types';
import {
  COLUMN_NAMES,
  ITEM_COMPLETED_STATUS,
  LABEL_STATUS_COLOR,
  MODAL_SUFFIX,
  HIDDEN_STATUS_NAMES,
  WELCOME_ITEMS_NAME,
} from '../constants';
import { getColumnValueRecord } from '../utils';

export function getMeetingInfo({
  item,
  columns,
  users,
  tagsRecord,
}: {
  item: Item;
  columns: Column[];
  users: User[];
  tagsRecord: Record<string, Tag>;
}) {
  const { columnValues, id, name } = item;
  const columnValueRecord = getColumnValueRecord(columnValues);

  const personCol = getColumnByName(columnValueRecord, COLUMN_NAMES.PERSON);
  const weekCol = getColumnByName(columnValueRecord, COLUMN_NAMES.WEEK);
  const statusCol = getColumnByName(columnValueRecord, COLUMN_NAMES.STATUS);
  const buttonTextCol = getColumnByName(columnValueRecord, COLUMN_NAMES.BUTTON_TEXT);
  const linkCol = getColumnByName(columnValueRecord, COLUMN_NAMES.LINK);
  const buttonLinkCol = getColumnByName(columnValueRecord, COLUMN_NAMES.BUTTON_LINK);
  const tagsCol = getColumnByName(columnValueRecord, COLUMN_NAMES.TAGS);

  const tagIds = tagsCol.value && JSON.parse(tagsCol.value)?.tag_ids;
  const tags = tagIds.map((id: string) => tagsRecord[id]);
  const personId = JSON.parse(personCol.value!).personsAndTeams[0].id;
  const user = users.find(user => user.id === personId);
  const { photoThumb, title, name: userName } = user || {};
  const linkUrl = getLinkUrl(linkCol);
  const buttonLink = getLinkUrl(buttonLinkCol);
  const labels = [getStatusLabel({ statusColValue: weekCol, columns })];
  const meetingsInfo = {
    linkSrc: linkUrl,
    userName: userName || '',
    position: title || '',
    avatarSrc: photoThumb || '',
    linkText: buttonTextCol.text,
    buttonLink,
    title: name,
    id: id,
    status: isItemCompleted(statusCol),
    statusColId: statusCol.id,
    labels,
    tags,
  };

  return meetingsInfo;
}

export function getMediaInfo({
  item,
  columns,
  assetsRecord,
  tagsRecord,
}: {
  item: Item;
  columns: Column[];
  assetsRecord: Record<string, string>;
  tagsRecord: Record<string, Tag>;
}) {
  const { columnValues, id, name } = item;
  const columnValueRecord = getColumnValueRecord(columnValues);

  const weekCol = getColumnByName(columnValueRecord, COLUMN_NAMES.WEEK);
  const statusCol = getColumnByName(columnValueRecord, COLUMN_NAMES.STATUS);
  const buttonTextCol = getColumnByName(columnValueRecord, COLUMN_NAMES.BUTTON_TEXT);
  const linkCol = getColumnByName(columnValueRecord, COLUMN_NAMES.LINK);
  const imgCol = getColumnByName(columnValueRecord, COLUMN_NAMES.IMAGE);
  const tagsCol = getColumnByName(columnValueRecord, COLUMN_NAMES.TAGS);

  const tagIds = tagsCol.value && JSON.parse(tagsCol.value)?.tag_ids;
  const tags = tagIds?.map((id: string) => tagsRecord[id]) || [];
  const linkUrl = getLinkUrl(linkCol);
  const imgColValue = imgCol.value;
  const parsedImgValue = imgColValue && JSON.parse(imgColValue);
  const assetsId = parsedImgValue?.files[0]?.assetId;
  const labels = [getStatusLabel({ statusColValue: weekCol, columns })];

  const mediaInfo = {
    id,
    imgSrc: assetsRecord[assetsId] || '',
    title: name,
    linkText: buttonTextCol.text,
    linkSrc: linkUrl,
    status: isItemCompleted(statusCol),
    statusColId: statusCol.id,
    labels,
    tags,
  };

  return mediaInfo;
}

export function getFirstTaskInfo(item: Item, columns: Column[], tagsRecord: Record<string, Tag>) {
  const { columnValues, id, name } = item;
  const columnValueRecord = getColumnValueRecord(columnValues);

  const weekCol = getColumnByName(columnValueRecord, COLUMN_NAMES.WEEK);
  const statusCol = getColumnByName(columnValueRecord, COLUMN_NAMES.STATUS);
  const linkCol = getColumnByName(columnValueRecord, COLUMN_NAMES.LINK);
  const tagsCol = getColumnByName(columnValueRecord, COLUMN_NAMES.TAGS);
  const buttonLinkCol = getColumnByName(columnValueRecord, COLUMN_NAMES.BUTTON_LINK);

  const tagIds = tagsCol.value && JSON.parse(tagsCol.value)?.tag_ids;
  const tags = tagIds?.map((id: string) => tagsRecord[id]) || [];

  const linkUrl = getLinkUrl(linkCol);
  const buttonLinkUrl = getLinkUrl(buttonLinkCol);

  const labels = [getStatusLabel({ statusColValue: weekCol, columns })];

  const taskInfo = {
    title: name,
    id,
    linkSrc: linkUrl,
    status: isItemCompleted(statusCol),
    statusColId: statusCol.id,
    labels,
    tags,
    buttonLinkUrl,
  };

  return taskInfo;
}

export function getRitualsInfo(item: Item, columns: Column[], tagsRecord: Record<string, Tag>) {
  const { columnValues, id, name } = item;
  const columnValueRecord = getColumnValueRecord(columnValues);

  const descCol = getColumnByName(columnValueRecord, COLUMN_NAMES.DESCRIPTION);
  const frequencyCol = getColumnByName(columnValueRecord, COLUMN_NAMES.FREQUENCY);

  const weekCol = getColumnByName(columnValueRecord, COLUMN_NAMES.WEEK);
  const statusCol = getColumnByName(columnValueRecord, COLUMN_NAMES.STATUS);
  const tagsCol = getColumnByName(columnValueRecord, COLUMN_NAMES.TAGS);

  const tagIds = tagsCol.value && JSON.parse(tagsCol.value)?.tag_ids;
  const tags = tagIds?.map((id: string) => tagsRecord[id]) || [];
  const labels = [
    getStatusLabel({ statusColValue: weekCol, columns }),
    getStatusLabel({
      statusColValue: statusCol,
      columns,
      withColor: true,
    }),
  ];

  const taskInfo = {
    title: name,
    id,
    description: descCol.text,
    labels,
    tags,
    frequency: frequencyCol.text,
  };

  return taskInfo;
}

export function getBecomingDesignerInfo(item: Item, _subItems: Record<string, SubItem[] | null>) {
  const { columnValues, id, name } = item;
  const columnValueRecord = getColumnValueRecord(columnValues);

  const statusCol = getColumnByName(columnValueRecord, COLUMN_NAMES.STATUS);
  const descCol = getColumnByName(columnValueRecord, COLUMN_NAMES.DESCRIPTION);
  const descLinkCol = getColumnByName(columnValueRecord, COLUMN_NAMES.DESCRIPTION_LINK);

  const linkInfo: { url: string; text: string } | null = descLinkCol?.value
    ? JSON.parse(descLinkCol.value)
    : null;

  const subItems = _subItems[id];
  const options = getSubItemsOptions(subItems);

  const status = options?.length
    ? !!options.every(option => option.status)
    : isItemCompleted(statusCol);

  const plan = {
    id,
    name,
    description: descCol.text,
    options,
    status,
    statusColId: statusCol.id,
    linkInfo,
  };

  return plan;
}

export function getWelcomeInfo(item: Item, users: User[]) {
  const { columnValues, id, name } = item;
  const columnValueRecord = getColumnValueRecord(columnValues);

  if (name === WELCOME_ITEMS_NAME.BUDDY || name === WELCOME_ITEMS_NAME.MENTOR) {
    const personCol = getColumnByName(columnValueRecord, COLUMN_NAMES.PERSON);
    const buttonTextCol = getColumnByName(columnValueRecord, COLUMN_NAMES.BUTTON_TEXT);
    const buttonLinkCol = getColumnByName(columnValueRecord, COLUMN_NAMES.BUTTON_LINK);
    const personId = JSON.parse(personCol.value!)?.personsAndTeams[0].id;
    const user = users.find(user => user.id === personId);
    const buttonLink = getLinkUrl(buttonLinkCol);

    const { photoThumb, title, name: userName } = user || {};

    const value = {
      title: name,
      id: id,
      linkText: buttonTextCol.text,
      userName: userName || '',
      position: title || '',
      avatarSrc: photoThumb || '',
      buttonLink,
    };

    const key = name === WELCOME_ITEMS_NAME.BUDDY ? 'buddy' : 'mentor';

    return { key, value: value };
  }

  if (name === WELCOME_ITEMS_NAME.ONBOARDING_LINK || name === WELCOME_ITEMS_NAME.PRODUCT_LINK) {
    const linkCol = getColumnByName(columnValueRecord, COLUMN_NAMES.LINK);
    const linkValue = getLinkValue(linkCol);
    const key = name === WELCOME_ITEMS_NAME.ONBOARDING_LINK ? 'onboardingLink' : 'productLink';

    return { key, value: linkValue };
  }

  if (name === WELCOME_ITEMS_NAME.TEAM_NAME) {
    const descCol = getColumnByName(columnValueRecord, COLUMN_NAMES.DESCRIPTION);
    const value = descCol.text;

    return { key: 'teamName', value };
  }

  return;
}

export function isAllSubitemsCompleted(subItems: SubItem[] | null) {
  if (!subItems) return false;

  return subItems.every(subItem => {
    const { columnValues } = subItem;
    const columnValueRecord = getColumnValueRecord(columnValues);
    const statusCol = getColumnByName(columnValueRecord, COLUMN_NAMES.STATUS);

    return isItemCompleted(statusCol);
  });
}

function getLinkUrl(linkCol?: ColumnValue): string {
  const parsedValue = getLinkValue(linkCol);
  return parsedValue.url;
}

function getLinkValue(linkCol?: ColumnValue) {
  if (!linkCol?.value)
    return {
      url: '',
      text: '',
    };
  let value = JSON.parse(linkCol.value) as LinkValue;
  value = {
    url: value.url?.trim(),
    text: value.text?.trim(),
  };
  // in defaut if text is empty monday.com sends url inside -- we don't want it
  if (value.text === value.url) value.text = '';
  return value;
}

function isItemCompleted(statusCol?: ColumnValue) {
  if (!statusCol) return false;

  return statusCol.text === ITEM_COMPLETED_STATUS;
}

function getSubItemsOptions(subItems: SubItem[] | null) {
  if (!subItems) return null;

  const options = subItems.reduce((acc, item) => {
    const { columnValues, id, name } = item;
    const columnValueRecord = getColumnValueRecord(columnValues);
    const statusCol = getColumnByName(columnValueRecord, COLUMN_NAMES.STATUS);
    const linkCol = getColumnByName(columnValueRecord, COLUMN_NAMES.LINK);

    const linkInfo: { url: string; text: string } | null = linkCol.value
      ? JSON.parse(linkCol.value)
      : null;

    acc.push({
      title: name,
      id,
      status: isItemCompleted(statusCol),
      linkInfo,
      boardId: Number(item.board.id),
    });

    return acc;
  }, [] as { title: string; id: string; status: boolean; boardId: number; linkInfo: { url: string; text: string } | null }[]);

  return options;
}

function getStatusLabel({
  statusColValue,
  columns,
  withColor = false,
}: {
  statusColValue: ColumnValue;
  columns: Column[];
  withColor?: boolean;
}) {
  const result = {
    text: '',
    color: null,
    textColor: '',
    columnId: '',
  };

  if (!statusColValue) return result;

  const { value, id, text } = statusColValue;
  const statusCol = columns.find(col => col.id === id);

  if (HIDDEN_STATUS_NAMES.includes(text)) return result;

  if (!value || !statusCol) {
    result.text = text;
    return result;
  }

  const parsedValue = JSON.parse(value);
  const columnSettings = JSON.parse(statusCol.settingsStr);

  const { labels_colors, labels } = columnSettings;
  const color = labels_colors[parsedValue.index]?.color;

  return {
    text: labels[parsedValue.index] || '',
    color: withColor ? color : null,
    textColor: withColor ? LABEL_STATUS_COLOR : '',
    columnId: statusCol.id,
  };
}

function getColumnByName(columnValueRecord: Record<string, ColumnValue>, columnName: string) {
  return columnValueRecord[columnName] || columnValueRecord[columnName + MODAL_SUFFIX];
}
