import { FC } from 'react';
import { CircleProgressBar } from '../../ui-kit';
import { observer } from 'mobx-react-lite';
import { appStore } from '../../stores';
import './Nav.scss';

export const Nav: FC = observer(() => {
  const structure = appStore.structure;
  const { rituals, meetings, media, designerPlanList, firstTasks } = structure;

  const taskPercent = calculatePercent(firstTasks.items);
  const becomingDesignerPercent = calculatePercent(designerPlanList.items);
  const lecturesPercent = calculatePercent(media.items);

  function calculatePercent(items: { status: boolean }[]) {
    if (!items.length) return 0;

    const completedItems = items.filter(item => item.status);

    const percent = Math.round((100 * completedItems.length) / items.length);

    return percent;
  }

  return (
    <div className="nav">
      <a href={`#${designerPlanList.path}`}>
        <div className="nav_item">
          <CircleProgressBar value={becomingDesignerPercent} />
          <span className="nav_item_title">Becoming a designer</span>
        </div>
      </a>
      <a href={`#${meetings.path}`}>
        <div className="nav_item nav_item-align">
          <span className="nav_item_title">Intro meetings</span>
        </div>
      </a>
      <a href={`#${media.path}`}>
        <div className="nav_item">
          <CircleProgressBar value={lecturesPercent} />
          <span className="nav_item_title">Lectures</span>
        </div>
      </a>
      <a href={`#${firstTasks.path}`}>
        <div className="nav_item">
          <CircleProgressBar value={taskPercent} />
          <span className="nav_item_title">First tasks</span>
        </div>
      </a>
      <a href={`#${rituals.path}`}>
        <div className="nav_item nav_item-align">
          <span className="nav_item_title">Rituals</span>
        </div>
      </a>
    </div>
  );
});
