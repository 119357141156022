"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Activity = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M9.98296 1.97614C7.85942 1.97614 5.82285 2.81971 4.32128 4.32128C2.81971 5.82285 1.97614 7.85942 1.97614 9.98296C1.97614 12.1065 2.81971 14.1431 4.32128 15.6446C5.82285 17.1462 7.85942 17.9898 9.98296 17.9898C12.1065 17.9898 14.1431 17.1462 15.6446 15.6446C17.1462 14.1431 17.9898 12.1065 17.9898 9.98296C17.9898 7.85942 17.1462 5.82285 15.6446 4.32128C14.1431 2.81971 12.1065 1.97614 9.98296 1.97614ZM5.38194 5.38194C6.6022 4.16167 8.25724 3.47614 9.98296 3.47614C11.7087 3.47614 13.3637 4.16167 14.584 5.38194C15.8042 6.6022 16.4898 8.25724 16.4898 9.98296C16.4898 11.7087 15.8042 13.3637 14.584 14.584C13.3637 15.8042 11.7087 16.4898 9.98296 16.4898C8.25724 16.4898 6.6022 15.8042 5.38194 14.584C4.16167 13.3637 3.47614 11.7087 3.47614 9.98296C3.47614 8.25724 4.16167 6.6022 5.38194 5.38194ZM7.29283 10.7453L8.48668 7.76067L10.3736 13.1057L10.3737 13.1058C10.4523 13.3285 10.5933 13.524 10.7798 13.669C10.9663 13.814 11.1905 13.9024 11.4257 13.9237C11.661 13.945 11.8974 13.8983 12.1069 13.7892C12.3165 13.6801 12.4902 13.5131 12.6076 13.3081L12.6078 13.3079L13.8044 11.2168H15.3047C15.7189 11.2168 16.0547 10.881 16.0547 10.4668C16.0547 10.0526 15.7189 9.71676 15.3047 9.71676H13.3695C13.1005 9.71676 12.8521 9.8608 12.7185 10.0943L11.5946 12.0583L9.66458 6.59126L9.66381 6.58909C9.57982 6.35351 9.4262 6.14904 9.2233 6.0028C9.02041 5.85656 8.77785 5.77547 8.5278 5.77029C8.27775 5.76511 8.03204 5.83609 7.82327 5.9738C7.61449 6.11151 7.45253 6.30945 7.35887 6.54134L7.35886 6.54134L7.35793 6.54368L6.08869 9.71676H4.66132C4.2471 9.71676 3.91132 10.0526 3.91132 10.4668C3.91132 10.881 4.2471 11.2168 4.66132 11.2168H6.59647C6.90315 11.2168 7.17893 11.0301 7.29283 10.7453Z", fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd" }) })));
};
Activity.displayName = 'Activity';
exports.default = Activity;
