import { FC, useState, useRef } from 'react';
import { Link, ModalContactCard } from '../../ui-kit';
import { ReactComponent as ArrowIcon } from '../../assets/modalArrow.svg';
import { ReactComponent as HandIcon } from '../../assets/handIcon.svg';
import { useClickOutside } from 'monday-ui-react-core';
import { observer } from 'mobx-react-lite';
import { appStore } from '../../stores';
import { Mentor } from '../../types';
import cn from 'classnames';
import './Modal.scss';

export const Modal: FC = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const { structure, user } = appStore;
  const { name } = user || {};
  const { welcome } = structure;
  const { teamName, buddy, mentor, productLink, onboardingLink } = welcome;
  const mentors = [buddy, mentor].filter(Boolean) as Mentor[];

  function onClose() {
    if (!isOpen) return;

    setIsOpen(false);
  }

  function toggle() {
    setIsOpen(!isOpen);
  }

  useClickOutside({
    ref: modalRef,
    callback: onClose,
  });

  return (
    <div ref={modalRef} className={cn('modal', { 'modal--open': isOpen })}>
      <span className="modal_title">{`Hi ${name}, 👋`}</span>
      <span className="modal_description">
        We're so glad you're joining us <br /> on the {teamName as String}
      </span>
      <div className="modal_blocks">
        {!!mentors.length &&
          mentors.map(mentor => (
            <ModalContactCard
              imgSrc={mentor.avatarSrc}
              name={mentor.userName}
              position={mentor.position}
              link={mentor.buttonLink}
              linkText={mentor.linkText}
              title={mentor.title}
              key={mentor.id}
            />
          ))}
      </div>
      {(!!productLink.url || !!onboardingLink.url) && (
        <div className="modal_links">
          <span className="modal_links_description">Start your design onboarding here:</span>
          <ul>
            {!!productLink.url && (
              <li>
                <Link
                  arrowIcon
                  className="modal_links_link"
                  linkSrc={productLink.url as string}
                  linkText={productLink.text || 'Product Design Onboarding'}
                />
              </li>
            )}
            {!!onboardingLink.url && (
              <li>
                <Link
                  arrowIcon
                  className="modal_links_link"
                  linkSrc={onboardingLink.url as string}
                  linkText={onboardingLink.text || 'Onboarding website'}
                />
              </li>
            )}
          </ul>
        </div>
      )}

      <div className="modal_button">
        <div
          onClick={toggle}
          className={cn('modal_button_wrapper', { 'modal_button_wrapper--open': isOpen })}
        >
          <div className="modal_button_arrow">
            <ArrowIcon />
          </div>
          <div className="modal_button_hand">
            <HandIcon />
          </div>
        </div>
      </div>
    </div>
  );
});
