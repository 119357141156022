import React, { useRef, useState } from 'react';
import {
  Avatar,
  Icon,
  TextField,
  Counter,
  AvatarGroup,
  DialogContentContainer,
  Chips,
  Dialog,
  Combobox,
  useClickOutside,
} from 'monday-ui-react-core';
import { PersonRound } from 'monday-ui-react-core/dist/allIcons';
import { ChecklistItemType, CustomArrayElement, User } from '../../types';
import { PersonOptionsRenderer } from '../PersonOptionsRenderer/PersonOptionsRenderer';
import { LabelsOptionsRenderer } from '../LabelsOptionsRenderer';
import './ModalInputs.scss';
import { appStore } from '../../stores';
import { MODAL_SUFFIX } from '../../constants';

// TODO: Unused component
export const ModalInputs: React.FC<{
  data: CustomArrayElement;
}> = ({ data }) => {
  const { title, type, value, columnId, options } = data;
  const formattedTitle = title.replace(new RegExp(`${MODAL_SUFFIX}$`), '');
  const [isPersonOpen, setIsPersonOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const personRef = useRef<HTMLDivElement | null>(null);
  const statusRef = useRef<HTMLDivElement | null>(null);

  function onPersonModalOpen() {
    if (isPersonOpen) return;

    setIsPersonOpen(true);
  }

  function onPersonModalClose() {
    //setTimeout is used to ensure it is called after onPersonModalClose function
    setTimeout(() => {
      setIsPersonOpen(false);
    });
  }

  function onStatusModalOpen() {
    if (isStatusOpen) return;

    setIsStatusOpen(true);
  }

  function onStatusModalClose() {
    //setTimeout is used to ensure it is called after onStatusModalOpen function
    setTimeout(() => {
      setIsStatusOpen(false);
    });
  }

  function onOptionClick(newUser: { value: number }) {
    const selectedUser = value as User[];
    const selectedUserId = selectedUser.map((user: User) => {
      return { id: String(user.id), kind: 'person' };
    });

    const updatedUsers = {
      personsAndTeams: [...selectedUserId, { id: newUser.value, kind: 'person' }],
    };
    const usersStr = JSON.stringify(updatedUsers);
    setIsPersonOpen(false);
    appStore.updateSelectedItem(columnId, type, usersStr);
    appStore.setColumnValuesToUpdate(columnId, usersStr);
  }

  function onUserDeleteClick(userId: number) {
    const selectedUser = value as User[];
    const selectedUserId = selectedUser
      .filter((user: User) => userId !== user.id)
      .map((user: User) => {
        return { id: String(user.id), kind: 'person' };
      });
    const updatedUsers = { personsAndTeams: selectedUserId };
    const usersStr = JSON.stringify(updatedUsers);
    appStore.updateSelectedItem(columnId, type, usersStr);
    appStore.setColumnValuesToUpdate(columnId, usersStr);
  }

  function onTextFieldChange(text: string) {
    appStore.updateSelectedItem(columnId, type, text);
    appStore.setColumnValuesToUpdate(columnId, JSON.stringify(text));
  }

  function onStatusChange(statusId: string, label: string) {
    const value = { index: Number(statusId) };
    const valueStr = JSON.stringify(value);
    setIsStatusOpen(false);
    appStore.updateSelectedItem(columnId, type, valueStr);
    appStore.setColumnValuesToUpdate(columnId, JSON.stringify({ label }));
  }

  if (type === ChecklistItemType.Person) {
    useClickOutside({
      ref: personRef,
      callback: onPersonModalClose,
    });

    const userOptions = options.map(userToOption);

    return (
      <div>
        <div className="item-modal_title">{formattedTitle}</div>
        <Dialog
          content={() => (
            <DialogContentContainer className="item-modal_dialog-container" ref={personRef}>
              <div className="item-modal_selected">
                {value.map(user => (
                  <Chips
                    key={user.id}
                    leftAvatar={user.photoThumb}
                    label={user.name}
                    id={user.id}
                    onDelete={onUserDeleteClick}
                  />
                ))}
              </div>
              <Combobox
                size={Combobox.sizes.SMALL}
                onClick={onOptionClick}
                options={userOptions}
                placeholder="Search"
                optionRenderer={PersonOptionsRenderer}
                optionsListHeight={200}
              />
            </DialogContentContainer>
          )}
          open={isPersonOpen}
          disable={!isPersonOpen}
          position={Dialog.positions.BOTTOM}
          zIndex={10}
        >
          <div onClick={onPersonModalOpen} className="item-modal_value">
            {value.length ? (
              <AvatarGroup
                type={Avatar.types.IMG}
                size={Avatar.sizes.SMALL}
                max={3}
                counterProps={{
                  color: Counter.colors.DARK!,
                  className: 'item-modal_counter',
                  maxDigits: 2,
                }}
              >
                {value.map(user => (
                  <Avatar
                    size={Avatar.sizes.SMALL}
                    key={user.id}
                    src={user.photoThumb}
                    ariaLabel={user.name}
                  />
                ))}
              </AvatarGroup>
            ) : (
              <Icon ignoreFocusStyle icon={PersonRound} iconSize={30} />
            )}
          </div>
        </Dialog>
      </div>
    );
  }

  if (type === ChecklistItemType.Color) {
    useClickOutside({
      ref: statusRef,
      callback: onStatusModalClose,
    });

    return (
      <div>
        <div className="item-modal_title">{formattedTitle}</div>
        <Dialog
          content={() => (
            <DialogContentContainer
              ref={statusRef}
              size="large"
              className="item-modal_status_dialog-container"
            >
              {options.map(option => {
                return (
                  <LabelsOptionsRenderer
                    onChange={onStatusChange}
                    id={option.id}
                    key={option.id}
                    label={option.text}
                    color={option.color}
                  />
                );
              })}
            </DialogContentContainer>
          )}
          open={isStatusOpen}
          disable={!isStatusOpen}
          position={Dialog.positions.BOTTOM}
          zIndex={10}
        >
          <div
            className="item-modal_value"
            style={{ backgroundColor: value.color, color: '#ffffff' }}
            onClick={onStatusModalOpen}
          >
            {value.text}
          </div>
        </Dialog>
      </div>
    );
  }

  return (
    <div>
      <div className="item-modal_title">{formattedTitle}</div>
      <TextField value={value} onChange={onTextFieldChange} />
    </div>
  );
};

function userToOption(user: User | null) {
  if (!user) return null;

  return {
    value: String(user.id),
    label: user.name,
    src: user.photoThumb,
  };
}
