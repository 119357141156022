"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const MoveArrowLeft = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M17.8921 10.071C17.8921 10.4853 17.5563 10.821 17.1421 10.821L4.81065 10.821L9.43897 15.4494C9.73186 15.7423 9.73186 16.2171 9.43897 16.51C9.14608 16.8029 8.6712 16.8029 8.37831 16.51L2.46966 10.6014C2.17677 10.3085 2.17677 9.83361 2.46966 9.54071L8.37831 3.63207C8.67121 3.33917 9.14608 3.33917 9.43897 3.63207C9.73187 3.92496 9.73187 4.39983 9.43897 4.69273L4.81065 9.32104L17.1421 9.32104C17.5563 9.32104 17.8921 9.65683 17.8921 10.071Z", fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd" }) })));
};
MoveArrowLeft.displayName = 'MoveArrowLeft';
exports.default = MoveArrowLeft;
