import { FC } from 'react';
import { Link } from '../Link';
import { Avatar } from 'monday-ui-react-core';
import './ModalContactCard.scss';

interface ModalContactCardProps {
  imgSrc: string;
  name: string;
  position: string;
  link: string;
  title: string;
  linkText: string;
}

export const ModalContactCard: FC<ModalContactCardProps> = ({
  imgSrc,
  name,
  linkText,
  position,
  link,
  title,
}) => {
  return (
    <div className="modal-card_wrapper">
      <div className="modal-card_block">
        <span className="modal-card_block_title">{title}</span>
        <div className="modal-card_block_avatar">
          <Avatar size={Avatar.sizes.LARGE} src={imgSrc} type={Avatar.types.IMG} />
        </div>

        <span className="modal-card_block_name">{name}</span>
        <span className="modal-card_block_position">{position}</span>
        <Link arrowIcon linkSrc={link} linkText={linkText} className="modal-card_block_link" />
      </div>
    </div>
  );
};
