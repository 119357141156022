import { appStore } from '../../stores';
import { Footer, Header, Main } from '..';
import { Modal } from '../Modal';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { ReactComponent as MobileMondayLogo } from '../../assets/mobileMondayLogo.svg';
import { Loader } from 'monday-ui-react-core';
import { PreScreen } from '../PreScreen';
import './App.scss';
import 'monday-ui-react-core/dist/main.css';
import 'react-circular-progressbar/dist/styles.css';

export const App = observer(() => {
  useEffect(() => {
    (async () => {
      await appStore.init();
    })();
  }, []);

  if (appStore.error) {
    return <span className="error-message">{appStore.error}</span>;
  }

  if (appStore.loading) {
    return (
      <div className="loader">
        <Loader size={Loader.sizes.LARGE} />
      </div>
    );
  }

  if (!appStore.accessGranted) {
    return (
      <div className="launch-screen">
        <h1 className="launch-screen_title">Hey 👋</h1>
        <span className="launch-screen_text">
          We're still working on this page, <br /> and we'll let you know when it's ready.
        </span>
      </div>
    );
  }

  if (!appStore.isDesigner) {
    return <PreScreen />;
  }

  if (!appStore.email) {
    return <PreScreen isLogin />;
  }

  return (
    <div className="app">
      <div className="app_wrapper app_desktop">
        <Header />
        <div className="app_content">
          <Main />
          <Footer />
        </div>
        <Modal />
      </div>

      <div className="app_mobile">
        <div className="app_mobile_logo">
          <MobileMondayLogo />
        </div>
        <div className="app_mobile_block">
          <h2 className="app_mobile_title">👋 Hey there</h2>
          <span className="app_mobile_text">
            Just a heads up: The onboarding process ain't built for mobile, so grab a desktop and
            work your magic. Welcome aboard!
          </span>
        </div>
      </div>
    </div>
  );
});
