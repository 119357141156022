import { FC } from 'react';
import { Checkbox } from '../Checkbox';
import emptyImage from '../../assets/emptyImage.png';
import { Label, Tag } from '../../types';
import { Chip } from '../Chip';
import { LABEL_STATUS_COLOR } from '../../constants';
import './MediaCard.scss';

interface MediaCardProps {
  linkText: string;
  linkSrc: string;
  imgSrc: string;
  title: string;
  checked: boolean;
  id: string;
  onChange: (itemId: string, value: boolean) => void;
  labels: Label[];
  tags: Tag[];
}

export const MediaCard: FC<MediaCardProps> = ({
  linkSrc,
  imgSrc,
  title,
  id,
  checked,
  onChange,
  labels,
  tags,
}) => {
  const filteredLabels = labels.filter(label => !!label.text);
  const filteredTags = tags.filter(Boolean);

  return (
    <a className="media-card_link" href={linkSrc} target="_blank">
      <div className="media-card">
        <div className="media-card_wrapper">
          <div className="media-card_wrapper_checkbox">
            <Checkbox id={id} value={checked} size="large" onChange={onChange} />
          </div>

          <img className="media-card_wrapper_img" src={imgSrc || emptyImage} />

          <div className="media-card_wrapper_box">
            <span className="media-card_wrapper_title">{title}</span>

            <div className="media-card_wrapper_labels">
              {filteredLabels.map(label => {
                return (
                  <Chip
                    text={label.text}
                    key={label.text}
                    bgColor={label.color}
                    textColor={label.color ? LABEL_STATUS_COLOR : ''}
                  />
                );
              })}
              {filteredTags.map(tag => {
                return <Chip text={tag.name} key={tag.id} />;
              })}
            </div>
          </div>
        </div>

        <div className="media-card_bg-figure" />
      </div>
    </a>
  );
};
