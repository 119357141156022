"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Gif = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M8.38281 12.9091C8.09268 13.2549 7.68256 13.5241 7.15245 13.7166 6.62234 13.9055 6.03492 14 5.39019 14 4.71323 14 4.11865 13.8538 3.60645 13.5615 3.09783 13.2656 2.70383 12.8378 2.42445 12.2781 2.14865 11.7184 2.00716 11.0606 2 10.3048V9.7754C2 8.99822 2.13074 8.3262 2.39221 7.75936 2.65726 7.18895 3.03694 6.75401 3.53123 6.45455 4.0291 6.15152 4.61115 6 5.27737 6 6.20506 6 6.93038 6.22103 7.45332 6.6631 7.97627 7.1016 8.2861 7.74153 8.38281 8.58289H6.81397C6.74233 8.13725 6.58294 7.81105 6.3358 7.60428 6.09223 7.3975 5.75554 7.29412 5.32572 7.29412 4.7777 7.29412 4.36042 7.49911 4.07388 7.90909 3.78733 8.31907 3.64227 8.9287 3.63868 9.73797V10.2353C3.63868 11.0517 3.79449 11.6684 4.10611 12.0856 4.41773 12.5027 4.87441 12.7112 5.47616 12.7112 6.08149 12.7112 6.5131 12.5829 6.77099 12.3262V10.984H5.30423V9.80214H8.38281V12.9091zM11.4077 13.893H9.79584V6.10695H11.4077V13.893zM17.6132 10.7112H14.5185V13.893H12.9066V6.10695H18V7.40642H14.5185V9.41711H17.6132V10.7112z", fill: "currentColor" }) })));
};
Gif.displayName = 'Gif';
exports.default = Gif;
