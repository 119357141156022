import { FC } from 'react';
import { ReactComponent as ChevronRight } from '../../assets/chevronRight.svg';
import { ReactComponent as ArrowIcon } from '../../assets/linkArrow.svg';
import cn from 'classnames';
import './Link.scss';

interface LinkProps {
  linkText: string;
  linkSrc?: string;
  className?: string;
  arrowIcon?: boolean;
}

export const Link: FC<LinkProps> = ({ linkText, linkSrc, className, arrowIcon = false }) => {
  return (
    <a href={linkSrc} target="_blank" className={cn('link', className)}>
      {linkText}
      {arrowIcon ? <ArrowIcon /> : <ChevronRight />}
    </a>
  );
};
