import { FC } from 'react';
import './Chip.scss';

interface ChipProps {
  text: string;
  bgColor?: string | null;
  textColor?: string;
  borderColor?: string;
}

export const Chip: FC<ChipProps> = ({ text, bgColor, textColor, borderColor }) => {
  const background = bgColor ? bgColor : '#ECEFF8';
  const border = borderColor ? `1px solid ${borderColor}` : '';

  return (
    <div className="custom-chip" style={{ backgroundColor: background, color: textColor, border }}>
      {text}
    </div>
  );
};
