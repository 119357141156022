import { FC } from 'react';
import { ReactComponent as Chevron } from '../../assets/chevron.svg';
import cn from 'classnames';
import './ToggleButton.scss';

interface ToggleButtonProps {
  value: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const ToggleButton: FC<ToggleButtonProps> = ({ value, disabled = false, onClick }) => {
  return (
    <div className={cn('toggle_btn', { toggle_btn_disabled: disabled })} onClick={onClick}>
      <div className={cn('toggle_btn_icon', { toggle_btn_icon_rotate: !value })}>
        <Chevron />
      </div>
    </div>
  );
};
