import { action, makeAutoObservable, observable, computed, runInAction } from 'mobx';
import {
  getStructure,
  isAllSubitemsCompleted,
  getBoardStructure,
  exchangeGoogleToken,
  setToken,
  getLastActivityLog,
  setItemValue,
  getMeData,
  removeToken,
  getToken,
  OnUnauthorizeManager,
} from '../services';
import {
  ChecklistItemType,
  Column,
  ColumnValue,
  CustomArrayElement,
  Item,
  Structure,
  SubItem,
  Tag,
  User,
} from '../types';
import {
  GROUP_NAMES,
  ITEM_COMPLETED_STATUS,
  MODAL_SUFFIX,
  STATUS_COLUMN_NAME,
  SUPPORTED_COLUMN_TYPES,
  SUPPORTED_DOMAINS,
} from '../constants';
import { extractDomainFromEmail, getStatusesInfo } from '../utils';
import { TokenResponse, googleLogout } from '@react-oauth/google';

class AppStore {
  constructor() {
    makeAutoObservable(this);
    OnUnauthorizeManager.setListener(this.logout);
  }

  boardId!: number;
  columns: Column[] = [];
  user?: User;
  users: User[] = [];
  email = '';
  subItems: Record<string, SubItem[] | null> = {};
  assetsRecord: Record<string, string> = {};
  tagsRecord: Record<string, Tag> = {};

  @observable accessGranted = true;
  @observable items: Item[] = [];
  @observable columnValuesToUpdate: string | null = null;
  @observable error = '';
  @observable loading = true;
  @observable selectedItem: null | Item = null;
  @observable isDesigner = true;

  @action
  login = async (response?: TokenResponse) => {
    try {
      if (!response) {
        runInAction(() => {
          this.error = 'Auth error';
        });
        return;
      }

      const { boardId, email, token } = await exchangeGoogleToken(response.access_token);

      if (!email || !token) {
        runInAction(() => {
          this.error = 'Auth error';
        });
        return;
      }

      setToken(token);

      const domain = extractDomainFromEmail(email);

      if (!SUPPORTED_DOMAINS.includes(domain)) {
        runInAction(() => {
          this.isDesigner = false;
        });
        return;
      }

      this.loading = true;
      await appStore.loadData(email, boardId);
    } catch (error) {
      console.log('Login issue', { error });
      runInAction(() => {
        this.error = 'Auth error';
      });
    }
  };

  @action
  logout = async () => {
    runInAction(() => {
      googleLogout();
      this.email = '';
      removeToken();
    });
  };

  @action
  init = async () => {
    const token = getToken();
    if (!token) {
      this.email = '';
      this.loading = false;
      return;
    }

    try {
      const { email, boardId } = (await getMeData()) || {};

      this.email = email;
      this.loading = true;
      await appStore.loadData(email, boardId);
    } catch (error) {
      this.email = '';
      this.loading = false;
    }
  };

  @action
  loadData = async (email?: string, boardId?: number) => {
    try {
      if (!email) {
        runInAction(() => {
          this.loading = false;
        });
        return;
      }

      if (!boardId) {
        runInAction(() => {
          this.accessGranted = false;
          this.loading = false;
        });

        return;
      }

      const { currentUser, users, items, columns, subItems, assets, tags } =
        await getBoardStructure({
          boardId,
          subitemsGroup: GROUP_NAMES.BECOMING_DESIGNER,
          assetsGroup: GROUP_NAMES.MEDIA,
        });

      runInAction(() => {
        this.loading = false;
        this.boardId = boardId;
        this.columns = columns;
        this.user = currentUser;
        this.users = users;
        this.items = items;
        this.subItems = subItems;
        this.assetsRecord = assets;
        this.email = email;
        this.tagsRecord = tags;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Something went wrong';
      });
    } finally {
      this.loading = false;
    }
  };

  @action
  changeSubItemStatus = async (itemId: string, subItemId: string, value: boolean) => {
    const selectedSubitem = this.subItems[itemId]?.find(subItem => subItem.id === subItemId);
    const statusColumn = selectedSubitem?.columnValues.find(
      colVal => colVal.title === STATUS_COLUMN_NAME
    );

    if (!selectedSubitem || !statusColumn) return;

    const boardId = Number(selectedSubitem.board.id);
    const uncheckMainItem = isAllSubitemsCompleted(this.subItems[itemId]) && value;

    const currentColumnValues = selectedSubitem.columnValues;

    const updatedColumnValues = selectedSubitem.columnValues.map(colValue => {
      if (colValue.id === statusColumn.id) {
        return {
          ...colValue,
          text: value ? '' : ITEM_COMPLETED_STATUS,
          value: value ? '' : JSON.stringify({ index: this.completedStatusId }),
        };
      }

      return colValue;
    });

    this.subItems[itemId] =
      this.subItems[itemId]?.map(subItem => {
        if (subItem.id === selectedSubitem.id) {
          return { ...subItem, columnValues: updatedColumnValues };
        }
        return subItem;
      }) || null;

    const checkMainItem = isAllSubitemsCompleted(this.subItems[itemId]);

    try {
      await this.updateStatusOnBoard({
        checked: value,
        itemId: Number(subItemId),
        boardId: boardId,
        columnId: statusColumn.id,
      });
    } catch (error) {
      this.subItems[itemId] =
        this.subItems[itemId]?.map(subItem => {
          if (subItem.id === selectedSubitem.id) {
            return { ...subItem, columnValues: currentColumnValues };
          }
          return subItem;
        }) || null;
    }

    if (checkMainItem || uncheckMainItem) {
      await this.changeItemStatus(itemId, uncheckMainItem);
    }
  };

  @action
  changeItemStatus = async (itemId: string, value: boolean, subItemId?: string) => {
    if (subItemId) {
      this.changeSubItemStatus(itemId, subItemId, value);
      return;
    }

    const selectedItem = this.items.find(item => item.id === itemId);
    const statusColumn = this.statusColumn;

    if (!selectedItem || !statusColumn) return;

    const currentColumnValues = selectedItem.columnValues;
    const updatedColumnValues = selectedItem.columnValues.map(colValue => {
      if (colValue.id === statusColumn.id) {
        return {
          ...colValue,
          text: value ? '' : ITEM_COMPLETED_STATUS,
          value: value ? '' : JSON.stringify({ index: this.completedStatusId }),
        };
      }

      return colValue;
    });

    this.items = this.items.map(item => {
      if (item.id === selectedItem.id) {
        return { ...item, columnValues: updatedColumnValues };
      }
      return item;
    });

    try {
      await this.updateStatusOnBoard({
        checked: value,
        itemId: +itemId,
        boardId: this.boardId,
        columnId: statusColumn.id,
      });
    } catch (error) {
      this.items = this.items.map(item => {
        if (item.id === selectedItem.id) {
          return { ...item, columnValues: currentColumnValues };
        }
        return item;
      });
    }
  };

  @action
  setColumnValuesToUpdate = (columnId: string, value: string) => {
    const colValues = this.columnValuesToUpdate ? JSON.parse(this.columnValuesToUpdate) : {};
    const parsedValue = JSON.parse(value);
    colValues[columnId] = parsedValue;
    if (parsedValue?.personsAndTeams) {
      colValues[columnId] = parsedValue.personsAndTeams.length ? parsedValue : null;
    }

    runInAction(() => {
      this.columnValuesToUpdate = JSON.stringify(colValues);
    });
  };

  @action
  updateBoard = async () => {
    // TODO: Unused function
    const itemId = this.selectedItem?.id;

    if (!itemId || !this.columnValuesToUpdate) return;

    this.items = this.items.map(item =>
      item.id === this.selectedItem?.id ? this.selectedItem : item
    );
  };

  @action
  setSelectedItem = (itemId: string | null) => {
    // TODO: Unused function
    const selectedItem = this.items.find(item => item.id === itemId);
    runInAction(() => {
      this.selectedItem = selectedItem || null;
    });
  };

  @action
  clearColumnValuesToUpdate = () => {
    // TODO: Unused function
    runInAction(() => {
      this.columnValuesToUpdate = null;
    });
  };

  @action
  updateSelectedItem = (columnId: string, type: string, value: string) => {
    const columnValues = this.selectedItem?.columnValues;

    if (!columnValues || !this.selectedItem) return;

    if (columnId === 'name') {
      this.selectedItem = { ...this.selectedItem, name: value };
      return;
    }

    const updatedColumnValues = columnValues.map(colValue => {
      if (colValue.id === columnId) {
        return type === 'text' ? { ...colValue, text: value } : { ...colValue, value };
      }

      return colValue;
    });

    this.selectedItem = { ...this.selectedItem, columnValues: updatedColumnValues };
  };

  @action
  async updateStatusOnBoard({
    checked,
    itemId,
    boardId,
    columnId,
  }: {
    checked: boolean;
    itemId: number;
    boardId: number;
    columnId: string;
  }) {
    const value = await this.getStatusText({
      checked,
      itemId,
      boardId,
      columnId,
    });

    await setItemValue({
      boardId,
      itemId,
      columnId,
      value,
    });
  }

  @computed
  get structure(): Structure {
    const structure = getStructure({
      items: this.items,
      columns: this.columns,
      users: this.users,
      subItems: this.subItems,
      assetsRecord: this.assetsRecord,
      tagsRecord: this.tagsRecord,
    });

    return structure;
  }

  @computed
  get completedStatusId() {
    const statusColumn = this.statusColumn;

    const settings = statusColumn ? JSON.parse(statusColumn.settingsStr) : {};
    const { labels } = settings;

    const completedStatusId =
      Object.keys(labels).find(key => labels[key] === ITEM_COMPLETED_STATUS) || '';

    return completedStatusId;
  }

  private async getStatusText({
    checked,
    itemId,
    boardId,
    columnId,
  }: {
    checked: boolean;
    itemId: number;
    boardId: number;
    columnId: string;
  }) {
    if (!checked) return ITEM_COMPLETED_STATUS;

    const { data } = await getLastActivityLog(boardId, +itemId, columnId);

    const { previous_value: previousValue, value } = JSON.parse(data || '{}');
    const currentText = value?.label?.text || '';
    const prevText = previousValue?.label?.text || '';

    const text = prevText === ITEM_COMPLETED_STATUS ? currentText : prevText;

    return text;
  }

  @computed
  get itemDetails() {
    if (!this.selectedItem) return null;

    const filteredValues = this.selectedItem.columnValues.filter(
      colValue =>
        SUPPORTED_COLUMN_TYPES.includes(colValue.type) && colValue.title.includes(MODAL_SUFFIX)
    );

    return this.transformItemsInfo(filteredValues);
  }

  private transformItemsInfo(colValues: ColumnValue[]): CustomArrayElement[] {
    const result = colValues.map(({ id, text, type, title, value }) => {
      const parsedValue = value && JSON.parse(value);
      if (type === 'color') {
        const currentColumn = this.columns.find(column => column.id === id);
        const options = getStatusesInfo(currentColumn);
        const index = String(parsedValue.index);

        const { text, color } = options[index];
        const labelsOptions = Object.values(options).filter(label => label.id !== index);

        return {
          title,
          type: ChecklistItemType.Color,
          columnId: id,
          options: labelsOptions,
          value: { id: index, text, color },
        };
      }

      if (type === 'multiple-person') {
        const personsAndTeams = parsedValue?.personsAndTeams || [];
        const usersOption = this.users.filter(
          user =>
            !personsAndTeams.find(
              (selectedUser: { id: number | string }) => Number(selectedUser.id) === user.id
            )
        );

        const selectedUsers = this.users.filter(user =>
          personsAndTeams.find(
            (selectedUser: { id: number | string }) => Number(selectedUser.id) === user.id
          )
        );

        return {
          title,
          type: ChecklistItemType.Person,
          columnId: id,
          options: usersOption,
          value: selectedUsers,
        };
      }

      return { title, type: ChecklistItemType.Text, columnId: id, value: text, options: null };
    }) as CustomArrayElement[];

    result.unshift({
      title: 'Name',
      type: ChecklistItemType.Text,
      columnId: 'name',
      value: this.selectedItem?.name || '',
      options: null,
    });

    return result;
  }

  private get statusColumn() {
    return this.columns.find(
      column =>
        column.title === STATUS_COLUMN_NAME || column.title === STATUS_COLUMN_NAME + MODAL_SUFFIX
    );
  }
}

export const appStore = new AppStore();
