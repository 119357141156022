import { Column, Item, Structure, SubItem, Tag, User } from '../types';
import { GROUP_NAMES, INIT_STRUCTURE_STATE } from '../constants';
import {
  getBecomingDesignerInfo,
  getFirstTaskInfo,
  getMediaInfo,
  getMeetingInfo,
  getRitualsInfo,
  getWelcomeInfo,
} from './columnParser';

export function getStructure({
  items,
  columns,
  users,
  subItems,
  assetsRecord,
  tagsRecord,
}: {
  items: Item[];
  columns: Column[];
  users: User[];
  subItems: Record<string, SubItem[] | null>;
  assetsRecord: Record<string, string>;
  tagsRecord: Record<string, Tag>;
}) {
  const initState = JSON.parse(JSON.stringify(INIT_STRUCTURE_STATE));

  const structure = items.reduce((acc, item) => {
    const { group } = item;
    const { title: groupName } = group;
    let result;

    if (groupName === GROUP_NAMES.BECOMING_DESIGNER) {
      result = getBecomingDesignerInfo(item, subItems);
      acc.designerPlanList.items.push(result);
      return acc;
    }
    if (groupName === GROUP_NAMES.MEDIA) {
      result = getMediaInfo({ item, columns, assetsRecord, tagsRecord });
      acc.media.items.push(result);
      return acc;
    }
    if (groupName === GROUP_NAMES.FIRST_TASKS) {
      result = getFirstTaskInfo(item, columns, tagsRecord);
      acc.firstTasks.items.push(result);
      return acc;
    }
    if (groupName === GROUP_NAMES.RITUALS) {
      result = getRitualsInfo(item, columns, tagsRecord);
      acc.rituals.items.push(result);
      return acc;
    }
    if (groupName === GROUP_NAMES.MEETINGS) {
      result = getMeetingInfo({ item, users, columns, tagsRecord });
      acc.meetings.items.push(result);
      return acc;
    }
    if (groupName === GROUP_NAMES.WELCOME) {
      const { key, value } = getWelcomeInfo(item, users) || {};
      if (!key || !value) return acc;

      acc.welcome[key] = value;

      return acc;
    }

    acc.unsupportedGroup.push(item);
    return acc;
  }, initState as Structure);

  return structure;
}
