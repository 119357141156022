import { FC } from 'react';
import { Avatar } from 'monday-ui-react-core';
import { Chip, Link } from '..';
import { ReactComponent as ButtonIcon } from '../../assets/buttonIcon.svg';
import { Label, Tag } from '../../types';
import './ContactCard.scss';

interface ContactCardProps {
  imgSrc: string;
  name: string;
  position: string;
  link: string;
  title: string;
  labels: Label[];
  linkText: string;
  buttonLink: string;
  tags: Tag[];
}

export const ContactCard: FC<ContactCardProps> = ({
  imgSrc,
  link,
  name,
  position,
  title,
  labels,
  linkText,
  buttonLink,
  tags,
}) => {
  const filteredLabels = labels.filter(label => !!label.text);
  const filteredTags = tags.filter(Boolean);

  return (
    <div className="contact-card">
      <div className="contact-card_bg">
        <div className="contact-card_wrapper">
          <div className="contact-card_wrapper_avatar">
            <Avatar size={Avatar.sizes.LARGE} src={imgSrc} type={Avatar.types.IMG} />
          </div>
          <span className="contact-card_wrapper_title">{title}</span>

          <span className="contact-card_wrapper_name">{name}</span>
          <span className="contact-card_wrapper_position">{position}</span>
          <div className="contact-card_wrapper_bottom-box">
            <div className="contact-card_wrapper_btn-wrapper">
              <div className="contact-card_wrapper_btn">
                <div className="contact-card_wrapper_btn_icon">
                  <ButtonIcon />
                </div>

                <a className="contact-card_wrapper_btn_link" href={link} target="_black">
                  See presentation
                </a>
              </div>

              <Link
                linkText={linkText}
                className="contact-card_wrapper_link"
                linkSrc={buttonLink}
                arrowIcon
              />
            </div>
            <div className="contact-card_wrapper_labels">
              {filteredLabels.map(label => {
                return (
                  <Chip
                    key={label.text}
                    text={label.text}
                    bgColor="inherit"
                    textColor="#ffffff"
                    borderColor="#ffffff"
                  />
                );
              })}
              {filteredTags.map(tag => {
                return (
                  <Chip
                    text={tag.name}
                    key={tag.id}
                    bgColor="inherit"
                    textColor="#ffffff"
                    borderColor="#ffffff"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
