import { FC } from 'react';
import { ReactComponent as ArrowCircleIcon } from '../../assets/arrowCircleIcon.svg';
import { Label, Tag } from '../../types';
import './Ritual.scss';

interface RitualProps {
  title: string;
  description: string;
  frequency: string;
  labels: Label[];
  tags: Tag[];
}

export const Ritual: FC<RitualProps> = ({ title, description, frequency }) => {
  return (
    <div className="ritual">
      <span className="ritual_title">{title}</span>
      <span className="ritual_description">{description}</span>
      <div className="ritual_wrapper">
        <ArrowCircleIcon />
        <span className="ritual_schedule">{frequency}</span>
      </div>
    </div>
  );
};
