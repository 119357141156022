import cn from 'classnames';
import { ReactComponent as SmallCheckMark } from '../../assets/smallCheckMark.svg';
import { ReactComponent as LargeCheckMark } from '../../assets/largeCheckMark.svg';
import './Checkbox.scss';

interface CheckboxProps {
  id: string;
  value: boolean;
  onChange: (itemId: string, value: boolean, subItemId?: string) => void;
  name?: string;
  label?: string | JSX.Element;
  labelClassName?: string;
  size?: 'small' | 'large';
  itemId?: string;
  disabled?: boolean;
}

export function Checkbox({
  id,
  label,
  name,
  labelClassName,
  onChange,
  value,
  size = 'small',
  itemId,
  disabled = false,
}: CheckboxProps) {
  function changeHandler() {
    const selectedItemId = itemId ? itemId : id;
    const subItemId = itemId && id;
    onChange(selectedItemId, value, subItemId);
  }

  const checkMark = size === 'small' ? <SmallCheckMark /> : <LargeCheckMark />;

  return (
    <div className={`checkbox-wrapper custom_checkbox_${size}`}>
      <input
        type="checkbox"
        style={{ display: 'none' }}
        checked={value}
        name={name}
        onChange={changeHandler}
        id={id}
      />
      <label
        htmlFor={id}
        className={cn('custom_checkbox', {
          custom_checkbox_checked: value,
          custom_checkbox_disabled: disabled,
        })}
      >
        {value && checkMark}
      </label>

      {label && <div className={labelClassName}>{label}</div>}
    </div>
  );
}
