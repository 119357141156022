import { FC } from 'react';
import './Section.scss';

interface SectionProps {
  children: React.ReactElement;
  title: string;
  description?: string;
  path: string;
}

export const Section: FC<SectionProps> = ({ children, title, description, path }) => {
  return (
    <section className="section" id={path}>
      <h2 className="section_title">{title}</h2>
      <span className="section_description">{description}</span>
      <div className="section_content"> {children} </div>
    </section>
  );
};
