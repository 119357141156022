import { FC, useRef } from 'react';
import { Button, Icon } from 'monday-ui-react-core';
import { observer } from 'mobx-react-lite';
import { appStore } from '../../stores';
import { ModalInputs } from '../../ui-kit';
import { Close } from 'monday-ui-react-core/dist/allIcons';
import './ItemDetailsModal.scss';

// TODO: Unused component
export const ItemDetailsModal: FC = observer(() => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const { itemDetails, setSelectedItem, updateBoard, clearColumnValuesToUpdate } = appStore;

  function onClose() {
    if (!itemDetails) return;

    setSelectedItem(null);
  }

  async function onClick() {
    await updateBoard();
    onClose();
    clearColumnValuesToUpdate();
  }

  return (
    <div ref={modalRef} className="item-modal">
      <div className="item-modal_header">
        <span>Information</span>
        <Icon
          iconType={Icon.type.SVG}
          clickable
          ignoreFocusStyle
          onClick={onClose}
          icon={Close}
          iconSize={16}
        />
      </div>
      <div className="item-modal_content">
        {itemDetails?.map(itemDetail => {
          return <ModalInputs key={itemDetail.columnId} data={itemDetail} />;
        })}
      </div>

      <Button color={Button.colors.POSITIVE} size={Button.sizes.MEDIUM} onClick={onClick}>
        Save
      </Button>
    </div>
  );
});
