import { FC } from 'react';
import { Checkbox, Chip, Link } from '..';
import { Label, Tag } from '../../types';
import './TaskBar.scss';

interface TaskBarProps {
  title: string;
  id: string;
  linkSrc: string;
  checked: boolean;
  onChange: (itemId: string, value: boolean) => void;
  labels: Label[];
  tags: Tag[];
}

export const TaskBar: FC<TaskBarProps> = ({
  title,
  id,
  checked,
  onChange,
  labels,
  tags,
  linkSrc,
}) => {
  const filteredLabels = labels.filter(label => !!label.text);
  const filteredTags = tags.filter(Boolean);

  return (
    <a className="block" target="_blank" href={linkSrc}>
      <div className="block_wrapper">
        <Checkbox
          onChange={onChange}
          value={checked}
          id={id}
          label={title}
          labelClassName="block_title"
        />
        <div className="block_labels">
          {filteredLabels.map(label => {
            return (
              <Chip
                text={label.text}
                key={label.text}
                bgColor={label.color}
                textColor={label.textColor}
              />
            );
          })}

          {filteredTags.map(tag => {
            return <Chip text={tag.name} key={tag.id} />;
          })}
        </div>
      </div>
      <div className="block_btn">
        <Link linkText="Open item" className="block_link" />
      </div>
    </a>
  );
};
