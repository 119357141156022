"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const TextBig = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M13.9499 16H13.95L12.7112 12.6374H12.7112L13.9499 16ZM5 16L9.5201 4H9.52007L4.99998 16H5ZM6.40768 16.5L7.63819 13.1374H12.3626L13.6013 16.5H15.7226L10.8258 3.5H9.17412L4.27734 16.5H6.40768ZM9.99589 5.2363L12.3913 11.772H12.3913L9.9959 5.23626L9.99589 5.2363ZM11.6755 11.272H8.31625L9.99587 6.68922L11.6755 11.272Z", fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd" }) })));
};
TextBig.displayName = 'TextBig';
exports.default = TextBig;
