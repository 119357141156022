import { FC } from 'react';
import { ReactComponent as NoAccessLogo } from '../../assets/no-access-logo.svg';
import { LoginButton } from '../LoginButton';
import './PreScreen.scss';

export const PreScreen: FC<{ isLogin?: boolean }> = ({ isLogin }) => {
  return (
    <div className="preScreen-block">
      <div className="center">
        <NoAccessLogo />
        {isLogin ? (
          <>
            <h2 className="loginTitle">Welcome aboard!</h2>
            <span className="loginText">
              Login to start your journey
              <br /> here at monday.com
            </span>
            <LoginButton />
          </>
        ) : (
          <>
            <h2 className="title">Designers only.</h2>
            <span className="text">Sorry, this page is off-limit to non-designers.</span>
          </>
        )}
      </div>
    </div>
  );
};
