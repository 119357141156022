"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const LogIn = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsxs)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M3.55925 3.60927C3.62922 3.53931 3.72411 3.5 3.82306 3.5H11.6846C11.7835 3.5 11.8784 3.53931 11.9484 3.60927C12.0184 3.67924 12.0577 3.77413 12.0577 3.87308V6.68077C12.0577 7.09498 12.3935 7.43077 12.8077 7.43077C13.2219 7.43077 13.5577 7.09498 13.5577 6.68077V3.87308C13.5577 3.37631 13.3603 2.89988 13.0091 2.54861C12.6578 2.19734 12.1814 2 11.6846 2H3.82306C3.32629 2 2.84986 2.19734 2.49859 2.54861C2.14732 2.89988 1.94998 3.37631 1.94998 3.87308V16.2269C1.94998 16.7237 2.14732 17.2001 2.49859 17.5514C2.84986 17.9027 3.32629 18.1 3.82306 18.1H11.6846C12.1814 18.1 12.6578 17.9027 13.0091 17.5514C13.3603 17.2001 13.5577 16.7237 13.5577 16.2269V13.4192C13.5577 13.005 13.2219 12.6692 12.8077 12.6692C12.3935 12.6692 12.0577 13.005 12.0577 13.4192V16.2269C12.0577 16.3259 12.0184 16.4208 11.9484 16.4907C11.8784 16.5607 11.7835 16.6 11.6846 16.6H3.82306C3.72411 16.6 3.62922 16.5607 3.55925 16.4907C3.48929 16.4208 3.44998 16.3259 3.44998 16.2269V3.87308C3.44998 3.77413 3.48929 3.67924 3.55925 3.60927Z", fill: "currentColor" }), (0, jsx_runtime_1.jsx)("path", { d: "M10.6527 7.83503C10.3598 7.54213 9.88491 7.54213 9.59201 7.83503L7.91794 9.5091C7.77594 9.64557 7.68756 9.83743 7.68756 10.0499C7.68756 10.2611 7.77485 10.4519 7.91531 10.5882L9.59201 12.2649C9.88491 12.5578 10.3598 12.5578 10.6527 12.2649C10.9456 11.972 10.9456 11.4972 10.6527 11.2043L10.2483 10.7999H16.2991C16.7133 10.7999 17.0491 10.4641 17.0491 10.0499C17.0491 9.63571 16.7133 9.29993 16.2991 9.29993H10.2484L10.6527 8.89569C10.9456 8.60279 10.9456 8.12792 10.6527 7.83503Z", fill: "currentColor" })] })));
};
LogIn.displayName = 'LogIn';
exports.default = LogIn;
