"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Note = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M5.98813 3.5C5.87833 3.5 5.77654 3.54275 5.70425 3.61333C5.63257 3.68331 5.5957 3.77435 5.5957 3.86538V16.1346C5.5957 16.2256 5.63257 16.3167 5.70425 16.3867C5.77654 16.4572 5.87833 16.5 5.98813 16.5H14.2699C14.3797 16.5 14.4815 16.4572 14.5538 16.3867C14.6255 16.3167 14.6624 16.2256 14.6624 16.1346V6.00039C14.6623 5.90955 14.6255 5.81856 14.5541 5.74867L12.3669 3.61325C12.2947 3.54277 12.193 3.50006 12.0833 3.5H5.98813ZM14.2699 18C14.7661 18 15.2455 17.8077 15.6017 17.46C15.9585 17.1116 16.1624 16.6352 16.1624 16.1346V6.00023C16.1623 5.49998 15.9586 5.02379 15.6021 4.67555L13.4146 2.53979C13.0585 2.19238 12.5793 2.0001 12.0835 2H5.98813C5.49194 2 5.01257 2.19227 4.65637 2.54005C4.29956 2.88841 4.0957 3.36478 4.0957 3.86538V16.1346C4.0957 16.6352 4.29956 17.1116 4.65637 17.46C5.01257 17.8077 5.49194 18 5.98813 18H14.2699ZM7.20117 9.2998C7.20117 8.88559 7.53696 8.5498 7.95117 8.5498H9.98058C10.3948 8.5498 10.7306 8.88559 10.7306 9.2998C10.7306 9.71402 10.3948 10.0498 9.98058 10.0498H7.95117C7.53696 10.0498 7.20117 9.71402 7.20117 9.2998ZM7.95117 12.0791C7.53696 12.0791 7.20117 12.4149 7.20117 12.8291C7.20117 13.2433 7.53696 13.5791 7.95117 13.5791H12.3335C12.7477 13.5791 13.0835 13.2433 13.0835 12.8291C13.0835 12.4149 12.7477 12.0791 12.3335 12.0791H7.95117Z", fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd" }) })));
};
Note.displayName = 'Note';
exports.default = Note;
