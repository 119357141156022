import { FC } from 'react';
import cn from 'classnames';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ReactComponent as SmallCheckMark } from '../../assets/smallCheckMark.svg';
import './CircleProgressBar.scss';

export const CircleProgressBar: FC<{ value: number }> = ({ value }) => {
  const itemCompleted = 100 === value;

  return (
    <div className={cn('circle-bar', { 'circle-bar_completed': itemCompleted })}>
      {itemCompleted ? (
        <SmallCheckMark />
      ) : (
        <CircularProgressbar
          value={value}
          strokeWidth={50}
          styles={buildStyles({
            strokeLinecap: 'butt',
            trailColor: '#ffffff',
            pathColor: '#6161ff',
          })}
        />
      )}
    </div>
  );
};
