"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Divider = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M2.96484 9.57129C2.96484 9.15708 3.30063 8.82129 3.71484 8.82129H16.2863C16.7005 8.82129 17.0363 9.15708 17.0363 9.57129C17.0363 9.9855 16.7005 10.3213 16.2863 10.3213H3.71484C3.30063 10.3213 2.96484 9.9855 2.96484 9.57129Z", fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd" }) })));
};
Divider.displayName = 'Divider';
exports.default = Divider;
