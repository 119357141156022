import { FC } from 'react';
import { Checkbox } from '../Checkbox';
import './CheckList.scss';

interface CheckListProps {
  options: {
    id: string;
    status: boolean;
    title: string;
    linkInfo: { url: string; text: string } | null;
  }[];
  onChange: (subItemId: string, value: boolean) => void;
  itemId: string;
}
export const CheckList: FC<CheckListProps> = ({ options, onChange, itemId }) => {
  return (
    <div className="check-list">
      {options.map(({ id, status, title, linkInfo }) => {
        let text = title as string | JSX.Element;

        if (linkInfo) {
          const replace = title.replace(
            linkInfo.text.trim(),
            `<a class="check-list_link" target="_blank" href="${linkInfo.url}">${linkInfo.text}</a>`
          );
          text = <span dangerouslySetInnerHTML={{ __html: replace }} />;
        }

        return (
          <Checkbox
            key={id}
            value={status}
            id={id}
            label={text}
            labelClassName="check-list_label"
            onChange={onChange}
            itemId={itemId}
          />
        );
      })}
    </div>
  );
};
