import axios from 'axios';
import { toCamelCase } from '../utils';
import { Column, Item, SubItem, Tag, User } from '../types';
import { getToken } from './local-storage';
import { appStore } from '../stores';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export function setupInterceptors() {
  apiClient.interceptors.request.use(config => {
    const token = getToken();

    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  });

  apiClient.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        OnUnauthorizeManager.handle();
      }
      return Promise.reject(error);
    }
  );
}

setupInterceptors();

export async function exchangeGoogleToken(googleToken: string) {
  try {
    const response = await apiClient.post('/auth', {
      googleToken,
    });

    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error exchanging Google token:', error);
    throw error;
  }
}

export async function getBoardStructure({
  boardId,
  assetsGroup,
  subitemsGroup,
}: {
  assetsGroup: string;
  subitemsGroup: string;
  boardId: number;
}) {
  try {
    const response = toCamelCase(
      await apiClient.get(`/boards/${boardId}/all-data`, {
        params: { assetsGroup, subitemsGroup },
      })
    ) as {
      data: {
        items: Item[];
        columns: Column[];
        users: User[];
        currentUser: User;
        tags: Record<string, Tag>;
        assets: Record<string, string>;
        subItems: Record<string, SubItem[] | null>;
      };
    };

    return response.data;
  } catch (error) {
    const customError = error as CustomError;
    if (customError?.response?.status === 401) {
      appStore.logout();
    }
    console.error('Failed to get board structure.', { error });

    throw error;
  }
}

export async function getLastActivityLog(boardId: number, itemId: number, columnId: string) {
  try {
    const response = toCamelCase(
      await apiClient.get('/activity-logs', {
        params: { boardId, itemId, columnId },
      })
    ) as { data: { data: string } };

    return response.data;
  } catch (error) {
    const customError = error as CustomError;
    if (customError?.response?.status === 401) {
      appStore.logout();
    }
    console.error('Failed to get last activity log.', { error });

    throw error;
  }
}

export async function setItemValue({ boardId, itemId, columnId, value }: SetItemValueProps) {
  try {
    const response = await apiClient.put(`/boards/${boardId}/items/${itemId}/columns/${columnId}`, {
      value,
    });

    const data = response.data;

    return data;
  } catch (error) {
    const customError = error as CustomError;
    if (customError?.response?.status === 401) {
      appStore.logout();
    }
    console.error('Error updating column value', error);
    throw error;
  }
}

export async function getMeData() {
  try {
    const response = (await apiClient.get('/users/me')) as {
      data: { email: string; boardId: number };
    };

    return response.data;
  } catch (error) {
    const customError = error as CustomError;
    if (customError?.response?.status === 401) {
      appStore.logout();
    }
    console.error('Failed to get me data.', { error });

    throw error;
  }
}

type SetItemValueProps = {
  boardId: number;
  itemId: number;
  columnId: string;
  value: string;
};

type CustomError = { response: { status: number } };

// Unused
export async function getGoogleUserInfo(token: string) {
  try {
    const response = await axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      }
    );

    return response.data as { email: string };
  } catch (error) {
    console.error('Failed to get user info from Google API.', { error });

    throw new Error('Auth fail');
  }
}

export async function getItemsAssets(boardId: number, itemsId: string[]) {
  try {
    const response = toCamelCase(
      await apiClient.get('/get-items-assets', {
        params: { boardId, itemsId: JSON.stringify(itemsId) },
      })
    ) as { data: Record<string, string> };

    return response.data;
  } catch (error) {
    console.error('Failed to get items assets.', { error });

    throw error;
  }
}

export async function getSubItems(boardId: number, itemsId: number[]) {
  try {
    const response = toCamelCase(
      await apiClient.get('/subitems', {
        params: { boardId, itemsId: JSON.stringify(itemsId) },
      })
    ) as { data: Record<string, SubItem[] | null> };

    return response.data;
  } catch (error) {
    console.error('Failed to get subitems.', { error });

    throw error;
  }
}

type VoidFn = () => void;
export class OnUnauthorizeManager {
  private static callback: VoidFn | null = null;

  static setListener(callback: VoidFn) {
    this.callback = callback;
  }

  static handle() {
    if (!this.callback) {
      console.error(
        'OnUnauthorizeManager.callaback was not set! Use "OnUnauthorizeManager.setListener" to set correct behavior'
      );
      return;
    }

    this.callback();
  }
}
