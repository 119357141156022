import { FC, useRef, useState } from 'react';
import mondayLogo from '../../assets/monday.svg';
import { Icon, Avatar, useClickOutside, DialogContentContainer } from 'monday-ui-react-core';
import { PersonRound, LogOut } from 'monday-ui-react-core/dist/allIcons';
import { Nav } from '../Nav';
import { observer } from 'mobx-react-lite';
import { appStore } from '../../stores';
import './Header.scss';

export const Header: FC = observer(() => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { photoThumb, id } = appStore.user || {};
  const modalRef = useRef<HTMLDivElement | null>(null);

  function onSignOutClick() {
    appStore.logout();
  }

  function onModalOpen() {
    setIsModalOpen(true);
  }

  function onCloseClick() {
    //setTimeout is used to ensure it is called after onOpenClick function
    setTimeout(() => {
      setIsModalOpen(false);
    });
  }

  useClickOutside({
    ref: modalRef,
    callback: onCloseClick,
  });

  const img = photoThumb?.includes('default') ? '' : photoThumb;
  const accountUrl = `https://monday.monday.com/users/${id}`;

  return (
    <div className="header-wrapper">
      <header className="header">
        <div className="header_logo">
          <Icon ignoreFocusStyle iconType={Icon.type.SRC} icon={mondayLogo} clickable={false} />
        </div>
        <Nav />
        <div className="header_avatar-wrapper" ref={modalRef}>
          <div className="header_person">
            {img ? (
              <Avatar
                onClick={onModalOpen}
                size={Avatar.sizes.MEDIUM}
                src={img}
                type={Avatar.types.IMG}
              />
            ) : (
              <div className="header_person_box">
                <div className="header_person_tooltip">
                  <a href={accountUrl} target="_blank">
                    Let’s update your picture
                  </a>
                </div>
                <Icon
                  onClick={onModalOpen}
                  ignoreFocusStyle
                  iconType={Icon.type.SRC}
                  icon={PersonRound}
                  clickable={false}
                  iconSize={44}
                />
              </div>
            )}
          </div>
          {isModalOpen && (
            <DialogContentContainer className="header_container">
              <div className="header_signOut" onClick={onSignOutClick}>
                <Icon ignoreFocusStyle iconType={Icon.type.SRC} icon={LogOut} />
                <span>Sign Out</span>
              </div>
            </DialogContentContainer>
          )}
        </div>
      </header>
    </div>
  );
});
