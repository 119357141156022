import React from 'react';
import { Avatar } from 'monday-ui-react-core';
import './PersonOptionsRenderer.scss';

type PersonOptionsRendererProps = { src: string; label: string };

export const PersonOptionsRenderer: React.FC<PersonOptionsRendererProps> = ({ src, label }) => {
  return (
    <div className="inline-container" key={label}>
      <Avatar size={Avatar.sizes!.SMALL} src={src} type={Avatar.types!.IMG} key={label} />
      <span className="inline-container_name">{label}</span>
    </div>
  );
};
