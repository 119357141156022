"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Dependency = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M2.77418 3.54835C2.34661 3.54835 2 3.20174 2 2.77418C2 2.34661 2.34661 2 2.77418 2L6.54533 2C6.97632 2.00054 7.40294 2.08629 7.80066 2.25232C8.19838 2.41834 8.55936 2.66136 8.86281 2.9674C9.16627 3.27344 9.40623 3.63646 9.56888 4.03558C9.73115 4.43377 9.81328 4.86005 9.81058 5.29001L9.81058 12.7824C9.81453 13.246 10.0016 13.6893 10.331 14.0157C10.661 14.3426 11.1071 14.5255 11.5716 14.5244L11.5734 14.5244L15.0189 14.5244L14.3337 13.9763C13.9998 13.7092 13.9456 13.222 14.2127 12.8881C14.4798 12.5543 14.967 12.5001 15.3009 12.7672L17.7094 14.6941C17.8931 14.841 18 15.0634 18 15.2986C18 15.5338 17.8931 15.7562 17.7094 15.9031L15.3009 17.83C14.967 18.0971 14.4798 18.0429 14.2127 17.709C13.9456 17.3752 13.9998 16.888 14.3337 16.6209L15.0188 16.0728L11.5738 16.0728C10.7004 16.0744 9.86182 15.7303 9.24131 15.1156C8.62047 14.5006 8.26843 13.6648 8.26222 12.7909L8.2622 5.28742L8.26224 5.28184C8.26388 5.05496 8.22064 4.83001 8.13502 4.61991C8.0494 4.40981 7.92308 4.21871 7.76334 4.05761C7.60359 3.8965 7.41357 3.76857 7.2042 3.68118C6.99497 3.59384 6.77055 3.5487 6.54382 3.54835L2.77418 3.54835ZM9.81058 5.29001L9.81058 5.28742L9.81056 5.293L9.81058 5.29001Z", fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd" }) })));
};
Dependency.displayName = 'Dependency';
exports.default = Dependency;
