import React from 'react';

import './LabelsOptionsRenderer.scss';

type LabelsOptionsRendererProps = {
  id: string;
  color: string;
  label: string;
  onChange: (id: string, label: string) => void;
};

export const LabelsOptionsRenderer: React.FC<LabelsOptionsRendererProps> = ({
  color,
  label,
  id,
  onChange,
}) => {
  return (
    <div
      onClick={() => onChange(id, label)}
      className="labels-container"
      key={label}
      style={{ backgroundColor: color }}
    >
      <span className="labels-container_name">{label}</span>
    </div>
  );
};
