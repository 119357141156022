import { Column, ColumnValue } from '../types';

export function getStatusesInfo(column?: Column) {
  if (!column) return {};
  const settings = JSON.parse(column.settingsStr);

  const { labels, labels_colors } = settings;

  const result: Record<string, { text: string; id: string; color: string }> = {};

  for (const labelId in labels) {
    const labelText = labels[labelId];
    const colorData = labels_colors[labelId];

    const objectData = {
      text: labelText,
      id: labelId,
      color: colorData.color,
    };

    result[labelId] = objectData;
  }

  return result;
}

export function getColumnValueRecord(columnValues: ColumnValue[]): Record<string, ColumnValue> {
  return columnValues.reduce((acc, colValue) => {
    const title = colValue.title;
    acc[title] = colValue;
    return acc;
  }, {} as Record<string, ColumnValue>);
}
