"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const StrikethroughT = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsxs)("g", Object.assign({ fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M10 1.82143C10.4142 1.82143 10.75 2.15721 10.75 2.57143V7.71428C10.75 8.1285 10.4142 8.46428 10 8.46428 9.58579 8.46428 9.25 8.1285 9.25 7.71428V2.57143C9.25 2.15721 9.58579 1.82143 10 1.82143zM6.39286 17.4286C6.39286 17.0143 6.72865 16.6786 7.14286 16.6786H12.8571C13.2714 16.6786 13.6071 17.0143 13.6071 17.4286 13.6071 17.8428 13.2714 18.1786 12.8571 18.1786H7.14286C6.72865 18.1786 6.39286 17.8428 6.39286 17.4286z" }), (0, jsx_runtime_1.jsx)("path", { d: "M4.85714 3.32143C4.75295 3.32143 4.65303 3.36282 4.57935 3.43649 4.50568 3.51017 4.46429 3.61009 4.46429 3.71428V5.42857C4.46429 5.84278 4.1285 6.17857 3.71429 6.17857 3.30007 6.17857 2.96429 5.84278 2.96429 5.42857V3.71428C2.96429 3.21227 3.16371 2.73081 3.51869 2.37583 3.87367 2.02085 4.35513 1.82143 4.85714 1.82143H15.1429C15.6449 1.82143 16.1263 2.02085 16.4813 2.37583 16.8363 2.73081 17.0357 3.21227 17.0357 3.71428V5.42857C17.0357 5.84278 16.6999 6.17857 16.2857 6.17857 15.8715 6.17857 15.5357 5.84278 15.5357 5.42857V3.71428C15.5357 3.61009 15.4943 3.51017 15.4207 3.43649 15.347 3.36282 15.2471 3.32143 15.1429 3.32143H4.85714zM2.96429 10.5714C2.96429 10.1572 3.30007 9.82143 3.71429 9.82143H16.2857C16.6999 9.82143 17.0357 10.1572 17.0357 10.5714 17.0357 10.9856 16.6999 11.3214 16.2857 11.3214H3.71429C3.30007 11.3214 2.96429 10.9856 2.96429 10.5714zM10 12.6786C10.4142 12.6786 10.75 13.0144 10.75 13.4286V17.4286C10.75 17.8428 10.4142 18.1786 10 18.1786 9.58579 18.1786 9.25 17.8428 9.25 17.4286V13.4286C9.25 13.0144 9.58579 12.6786 10 12.6786z" })] })) })));
};
StrikethroughT.displayName = 'StrikethroughT';
exports.default = StrikethroughT;
