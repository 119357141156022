import camelcaseKeys from 'camelcase-keys';

export function toCamelCase(response: object) {
  return camelcaseKeys(response, { deep: true });
}

export function insertSpaceBeforeUppercase(text: string): string {
  return text
    .split('')
    .map((char, index) => {
      if (char === char.toUpperCase() && index > 0) {
        return ' ' + char;
      }
      return char;
    })
    .join('');
}
